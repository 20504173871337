import { Box } from "@mui/material"
import CssBaseline from "@mui/material/CssBaseline"
import Grid from "@mui/material/Grid2"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import FooterLinks from "layout/PublicLayout/FooterLinks"
import Logo from "layout/PublicLayout/Logo"
import SideImage from "layout/PublicLayout/SideImage"
import React, { ReactNode } from "react"
import ErrorBoundary from "shared/component/ErrorBoundary"
import CustomTheme from "styles/theme/CustomTheme"

type Props = {
    children?: ReactNode
    showLogo?: boolean
    showFooter?: boolean
}

export const PublicLayout = (props: Props) => {
    return (
        <Box className={"public-layout"}>
            <CustomTheme>
                <Grid className={"main-container"} container component="main" sx={{ height: "100vh" }}>
                    <CssBaseline />
                    <SideImage
                        size={{
                            xs: 0,
                            sm: 5,
                            md: 5,
                        }}
                        sx={{
                            "@media (max-width:600px)": {
                                display: "none",
                            },
                        }}
                    >
                        <Typography
                            component="h2"
                            sx={{
                                fontSize: "64px!important",
                                "@media (max-width:1024px)": {
                                    fontSize: "32px!important",
                                },
                                marginBottom: "1em",
                                color: "white",
                                lineHeight: "150%!important",
                                textShadow: "0px 1px 20px rgba(0, 0, 0, 0.9)",
                            }}
                        >
                            FUTURE-PROOF YOUR MARKETING SUCCESS
                        </Typography>
                        <Typography
                            component="p"
                            sx={{
                                fontSize: "36px!important",
                                lineHeight: "150%!important",
                                "@media (max-width:1024px)": {
                                    fontSize: "18px!important",
                                },
                                color: "white",
                                textShadow: "0px 1px 20px rgba(0, 0, 0, 0.9)",
                            }}
                        >
                            Better insights. Better marketing decisions. Better results.
                        </Typography>
                    </SideImage>
                    <Grid
                        className={"content-container"}
                        component={Paper}
                        elevation={0}
                        square
                        size={{
                            xs: 12,
                            sm: 7,
                            md: 7,
                        }}
                    >
                        <Box
                            sx={{
                                position: "relative",
                                m: 4,
                                mt: 0,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "100%",
                            }}
                        >
                            {props.showLogo && <Logo sx={{ width: "150px", marginBottom: "30px" }} />}
                            <ErrorBoundary>
                                <Box className={"public-content"} sx={{ maxWidth: "600px" }}>
                                    {props.children}
                                </Box>
                            </ErrorBoundary>
                            {props.showFooter && (
                                <FooterLinks
                                    sx={{
                                        width: "100%",
                                        position: "absolute",
                                        bottom: "50px",
                                    }}
                                />
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </CustomTheme>
        </Box>
    )
}

export default PublicLayout
