import { DimensionMetricCompatibility } from "domain/ColumnConfigurator/DimensionMetricCompatibility"
import { Tag } from "domain/ColumnConfigurator/TagsUtil"
import { SelectedState } from "domain/ColumnConfigurator/components/types"
import { ComputationType } from "domain/types"

export type ColumnUniqueName = string
export type DataGroupUniqueName = string
export type MetricsFrontendGroupUniqueName = string

export type DataColumn = Dimension | Metric
export interface Dimension {
    readonly columnType: "dimension"
    readonly uniqueName: ColumnUniqueName
    readonly displayName: string
}

export type Dimensions = ReadonlyMap<ColumnUniqueName, Dimension>

export interface Metric {
    readonly columnType: "metric"
    readonly uniqueName: ColumnUniqueName
    /**
     * Full display name of the metric.
     *
     * Example: "Revenue (Digital, ToCnv)" for metric with `uniqueName` "revenue_attributed_tocnv"
     */
    readonly displayName: string
    readonly round: number
    /**
     * `displayName` without tags for attribution timing etc.
     *
     * Example: "Revenue" for metric with `uniqueName` "revenue_attributed_tocnv"
     */
    readonly displayNameBaseMetric: string
    readonly tags?: ReadonlySet<Tag>

    readonly computationType: ComputationType
}

export type Metrics = ReadonlyMap<ColumnUniqueName, Metric>

export interface DataGroup {
    readonly uniqueName: DataGroupUniqueName
    readonly description: string
    readonly dimensions: ReadonlySet<ColumnUniqueName>
    readonly metrics: ReadonlySet<ColumnUniqueName>
}

export type DataGroups = ReadonlyMap<DataGroupUniqueName, DataGroup>

export interface MetricsFrontendGroup {
    readonly uniqueName: MetricsFrontendGroupUniqueName
    readonly displayName: string
    readonly metrics: ReadonlySet<ColumnUniqueName>
    readonly sortOrder: number
}

export type MetricsFrontendGroups = ReadonlyMap<MetricsFrontendGroupUniqueName, MetricsFrontendGroup>

export interface ColumnInfoText {
    readonly uniqueName: ColumnUniqueName
    readonly infoText: string
}
export type ColumnInfoTexts = ReadonlyMap<ColumnUniqueName, ColumnInfoText>

export interface AttributionTiming {
    readonly id: "tocnv" | "totp"
    readonly displayName: string
    readonly tag: Tag.TOCNV | Tag.TOTP
}

export class ToCnvAttributionTiming implements AttributionTiming {
    readonly id = "tocnv"
    readonly displayName = "ToCnv"
    readonly tag = Tag.TOCNV

    static readonly instance: ToCnvAttributionTiming = new ToCnvAttributionTiming()
}
export class ToTPAttributionTiming implements AttributionTiming {
    readonly id = "totp"
    readonly displayName = "ToTP"
    readonly tag = Tag.TOTP

    static readonly instance: ToTPAttributionTiming = new ToTPAttributionTiming()
}

export interface ValueType {
    readonly id: "original" | "returned" | "updated"
    readonly displayName: string
    readonly tag: Tag.ORIGINAL | Tag.RETURNED | Tag.UPDATED
}
export class OriginalValueType implements ValueType {
    readonly id = "original"
    readonly displayName = "Original"
    readonly tag = Tag.ORIGINAL

    static readonly instance: OriginalValueType = new OriginalValueType()
}

export class ReturnedValueType implements ValueType {
    readonly id = "returned"
    readonly displayName = "Returned"
    readonly tag = Tag.RETURNED

    static readonly instance: ReturnedValueType = new ReturnedValueType()
}
export class UpdatedValueType implements ValueType {
    readonly id = "updated"
    readonly displayName = "Updated"
    readonly tag = Tag.UPDATED

    static readonly instance: UpdatedValueType = new UpdatedValueType()
}

export enum Model {
    COMMISSION = "COMMISSION",
    ENHANCED = "ENHANCED",
    DIGITAL = "DIGITAL",
    AVM = "AVM",
}

export interface SupportedModels {
    readonly [ToCnvAttributionTiming.instance.id]: SupportedModel | UnsupportedModel
    readonly [ToTPAttributionTiming.instance.id]: SupportedModel | UnsupportedModel
}

export interface UnsupportedModel {
    readonly isSupported: false
}

export interface SupportedModel {
    readonly isSupported: true
    readonly [UpdatedValueType.instance.id]: boolean
    readonly [OriginalValueType.instance.id]: boolean
    readonly [ReturnedValueType.instance.id]: boolean
}

export class WidgetStaticConfiguration {
    readonly supportedDataGroups: DataGroupUniqueName[]
    readonly maxDimensions: number
    readonly supportedColumnSettings: ColumnSetting[]
    readonly initiallyExpandedGroups: Set<MetricsFrontendGroupUniqueName>

    constructor({
        supportedDataGroups,
        maxDimensions,
        supportedColumnSettings,
        initiallyExpandedGroups,
    }: {
        uiElementId?: number | undefined
        supportedDataGroups: DataGroupUniqueName[]
        maxDimensions: number
        supportedColumnSettings: ColumnSetting[]
        initiallyExpandedGroups?: Set<MetricsFrontendGroupUniqueName>
    }) {
        this.supportedDataGroups = supportedDataGroups ?? []
        this.maxDimensions = maxDimensions ?? 0
        this.supportedColumnSettings = supportedColumnSettings ?? []
        this.initiallyExpandedGroups = initiallyExpandedGroups ?? new Set<MetricsFrontendGroupUniqueName>()
    }

    /**
     * Checks whether the dimensions are supported.
     */
    areDimensionsSupported() {
        return this.maxDimensions > 0
    }

    /**
     * Checks whether the column setting is supported.
     *
     * @param columnSetting
     */
    isColumnSettingSupported(columnSetting: ColumnSetting) {
        return this.supportedColumnSettings.includes(columnSetting)
    }

    /**
     * Checks whether the selected state fits the widget configuration.
     *
     * @param selectedState
     * @param dimensionMetricCompatibility
     */
    isSelectedStateValid(selectedState: SelectedState, dimensionMetricCompatibility: DimensionMetricCompatibility) {
        return (
            selectedState.selectedDimensions.length <= this.maxDimensions &&
            (this.maxDimensions === 0 || selectedState.selectedDimensions.length > 0) &&
            selectedState.getCompatibleSelectedMetrics(dimensionMetricCompatibility).length > 0
        )
    }
}

export enum ColumnSetting {
    SHOW_BARS = "showBars",
}

export type ColumnConfiguratorLabels = {
    metric: string
    metricPlural: string
}
export const DEFAULT_COLUMN_CONFIGURATOR_LABELS: ColumnConfiguratorLabels = {
    metric: "metric",
    metricPlural: "metrics",
}
