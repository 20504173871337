export const FONT_SIZE_BODY_XX_SMALL = "11px"
export const FONT_SIZE_BODY_X_SMALL = "12px"
export const FONT_SIZE_BODY_SMALL = "13px"
export const FONT_SIZE_BODY_MEDIUM = "14px"
export const FONT_SIZE_BODY_LARGE = "15px"
export const FONT_SIZE_BODY_MAIN_HEADLINE = "18px"
export const FONT_SIZE_BODY_X_LARGE = "20px"
export const FONT_WEIGHT_SEMI_THIN = 100
export const FONT_WEIGHT_EXTRA_LIGHT = 200
export const FONT_WEIGHT_LIGHT = 300
export const FONT_WEIGHT_REGULAR = 400
export const FONT_WEIGHT_MEDIUM = 500
export const FONT_WEIGHT_SEMI_BOLD = 600
export const FONT_WEIGHT_BOLD = 700
export const FONT_WEIGHT_EXTRA_BOLD = 800
export const FONT_WEIGHT_BLACK = 900
export const BORDER_RADIUS = "5px"
export const BORDER_RADIUS_SMALL = "3px"
