import axios, { AxiosRequestConfig, AxiosResponse, CreateAxiosDefaults } from "axios"
import { loginFailure } from "domain/authentication/redux/authentication.slice"
import { ApiErrorDTO } from "domain/types"
import { QueryIdentifier } from "generated/models"
import * as R from "remeda"
import store from "shared/redux/store"
import UrlService from "shared/service/url.service"
import UrlUtil from "shared/util/UrlUtil"
import { log } from "shared/util/log"
import { v4 as uuid } from "uuid"

/**
 * Default configuration, applied to all custom configs.
 */
const defaultConfig: CreateAxiosDefaults = {
    baseURL: UrlService.getGatewayUrl(),
    timeout: 30000,
    withCredentials: true,
    withXSRFToken: true,
}

// TODO: Workaround for https://github.com/axios/axios/issues/5153
//   Remove this once axios has fixed this issue
declare module "axios" {
    interface Cancel {
        readonly __CANCEL__: true
    }
}

export const axiosInstance = axios.create({
    ...defaultConfig,
})

/**
 * Executes a GET request against the given URI.
 */
export const request = <T>(
    url: string,
    method: string,
    responseType: string,
    handleResponse: any,
    config?: AxiosRequestConfig,
    logRequest: boolean = true,
) => {
    if (logRequest) {
        log.info(`GET request to ${url} with axios config `, config)
    }
    return axiosInstance.request(config).then(handleResponse).catch(handleException)
}

/**
 * Executes a GET request against the given URI.
 */
export const get = <T>(url: string, config?: AxiosRequestConfig, logRequest: boolean = true) => {
    // if we do not have a standard params serializer, we will add this default one
    // it will handle brackets inside JSON strings correctly when URL encoding them
    config = config || {}
    if (!config.paramsSerializer) {
        config.paramsSerializer = (params) => {
            const mappedParams = R.mapValues(params, (value) => encodeURI(JSON.stringify(value)))
            const entries = R.entries(mappedParams)
            return entries.reduce((result, [key, value]) => {
                return (result ? result + "&" : "") + key + "=" + value
            }, "")
        }
    }

    if (logRequest) {
        log.info(`GET request to ${url} with axios config `, config)
    }

    return axiosInstance.get(url, config).then(handleResponse).catch(handleException)
}

export interface IdentifiableData {
    queryIdentifier: QueryIdentifier
    [key: string]: any
}

/**
 * Sends @param data to the given @param api via POST request.
 * It generates a unique queryIdentifier for the request.
 * If the request is canceled, it will send a DELETE request to the cancel endpoint.
 * This cancel request will be broadcast to all service instances.
 */
export const postData = async <T>(
    baseUrl: string,
    api: string,
    data: { [key: string]: any },
    config?: AxiosRequestConfig,
): Promise<T> =>
    await postCancellableData(
        baseUrl,
        api,
        {
            ...data,
            queryIdentifier: {
                value: uuid(),
            },
        },
        config,
    )

/**
 * Sends @param data to the given @param api via POST request.
 * If the request is canceled, it will send a DELETE request to the cancel endpoint.
 * This cancel request will be broadcast to all service instances.
 */
export const postCancellableData = async <T>(
    baseUrl: string,
    api: string,
    data: IdentifiableData,
    config?: AxiosRequestConfig,
): Promise<T> => {
    const url = UrlUtil.joinUrl(baseUrl, api)
    log.info(`POST request to ${url} with axios config `, config)

    return axiosInstance
        .post(url, data, config)
        .then(handleResponse)
        .catch(async (thrown): Promise<ApiErrorDTO> => {
            if (axios.isCancel(thrown)) {
                log.debug("Query was cancelled: ", url)
                const cancelUrl = UrlUtil.joinUrl(baseUrl, "broadcast", "cancelQuery", data.queryIdentifier.value)
                deleteRequest(cancelUrl)
            }

            return handleException(thrown)
        })
}

/**
 * Executes a POST request against the given URI.
 */
export const post = <T>(url: string, data: any, config?: AxiosRequestConfig) => {
    log.info(`POST request to ${url} with axios config `, config)

    return axiosInstance.post(url, data, config)?.then(handleResponse).catch(handleException)
}

/**
 * Executes a PATCH request against the given URI.
 */
export const patch = <T>(url: string, data: any, config?: AxiosRequestConfig) => {
    log.info(`PATCH request to ${url} with axios config `, config)

    return axiosInstance.patch(url, data, config).then(handleResponse).catch(handleException)
}

/**
 * Executes a PUT request against the given URI.
 */
export const put = <T>(url: string, data: any, config?: AxiosRequestConfig) => {
    log.info(`PUT request to ${url} with axios config `, config)

    return axiosInstance.put(url, data, config).then(handleResponse).catch(handleException)
}

/**
 * Executes a DELETE request against the given URI.
 */
export const deleteRequest = <T>(url: string, config?: AxiosRequestConfig) => {
    log.info(`DELETE request to ${url} with axios config `, config)

    return axiosInstance.delete(url, config).then(handleResponse).catch(handleException)
}

/**
 * Handle successful responses. Basically returns the JSON data from the response.
 */
const handleResponse = <T>(response: AxiosResponse<T>): T => {
    // even if we receive an error, the backend will send as an ApiErrorDto
    return response.data
}

/**
 * Handle error cases. If we (think we) found an ApiErrorDto we will reject with that, otherwise
 * we create an ApiError from the error object we got.
 */
const handleException = async (e): Promise<ApiErrorDTO> => {
    // special case: if we have unauthorized/forbidden requests, we will trigger login again
    if (e.response && e.response.status === 401) {
        const sleep = (millis) => {
            return new Promise((resolve) => setTimeout(resolve, millis))
        }

        store.dispatch(loginFailure())

        await sleep(5000)

        return Promise.resolve({ httpStatus: 200, message: "" })
    }

    // maybe we got an ApiErrorDto
    if (e.response && e.response.data) {
        return Promise.reject(e.response.data)
    }

    // if not, we will create one to streamline the handleException API
    return Promise.reject({
        message: e.toString(),
        errors: [e.toString()],
    })
}
