import { ColumnUniqueName } from "domain/ColumnConfigurator/types"

export enum Tag {
    COMMISSION = "Commission",
    DIGITAL = "DDA",
    ENHANCED = "DDA Enhanced",
    AVM = "DDA with Views",
    ORIGINAL = "Original",
    RETURNED = "Returned",
    UPDATED = "Updated",
    TOCNV = "ToCnv",
    TOTP = "ToTP",
}

/**
 * Some column names do not follow the naming convention and therefore need to be assigned manually.
 */
const STATIC_TAG_ASSIGNMENTS: Record<ColumnUniqueName, Tag[]> = {
    combined_kpi_effectiveness_change_attributed_tocnv: [Tag.DIGITAL, Tag.UPDATED, Tag.TOCNV],
    combined_kpi_return_per_touchpoint_tocnv: [Tag.COMMISSION, Tag.UPDATED, Tag.TOCNV],
    combined_kpi_tchibo_cdr: [Tag.ENHANCED, Tag.ORIGINAL, Tag.TOCNV],
    combined_kpi_tchibo_cdr_net: [Tag.ENHANCED, Tag.ORIGINAL, Tag.TOCNV],
    combined_kpi_cj_contact_rate_attributed_totp: [Tag.TOTP],
    combined_kpi_conversions_rate_sales_attributed_totp: [Tag.ENHANCED, Tag.UPDATED, Tag.TOTP],
    combined_kpi_conversions_rate_sales_attributed_tocnv: [Tag.ENHANCED, Tag.UPDATED, Tag.TOCNV],
    conversions_attributed_avm_change: [Tag.AVM, Tag.UPDATED, Tag.TOCNV],
    conversions_attributed_avm_change_percent: [Tag.AVM, Tag.UPDATED, Tag.TOCNV],
    conversions_attributed_change: [Tag.DIGITAL, Tag.UPDATED, Tag.TOCNV],
    conversions_attributed_change_percent: [Tag.DIGITAL, Tag.UPDATED, Tag.TOCNV],
    conversions_attributed_extended_change: [Tag.ENHANCED, Tag.UPDATED, Tag.TOCNV],
    conversions_attributed_extended_change_percent: [Tag.ENHANCED, Tag.UPDATED, Tag.TOCNV],
    conversion_rate_combined: [Tag.COMMISSION, Tag.UPDATED, Tag.TOCNV],
    conversion_tp_rate_combined: [Tag.COMMISSION, Tag.UPDATED, Tag.TOCNV],
    cvr_clicks_combined: [Tag.COMMISSION, Tag.UPDATED, Tag.TOCNV],
    cvr_postview_combined: [Tag.COMMISSION, Tag.UPDATED, Tag.TOCNV],
    roi_fixed: [Tag.COMMISSION, Tag.UPDATED, Tag.TOCNV],
    tv_revenue_attributed_mediaplan: [Tag.DIGITAL, Tag.UPDATED, Tag.TOCNV],
    tv_total_price_attributed_mediaplan: [Tag.DIGITAL, Tag.UPDATED, Tag.TOCNV],
    tv_leads_attributed_mediaplan: [Tag.DIGITAL, Tag.UPDATED, Tag.TOCNV],
    tv_sales_attributed_mediaplan: [Tag.DIGITAL, Tag.UPDATED, Tag.TOCNV],
    tv_conversions_attributed_mediaplan: [Tag.DIGITAL, Tag.UPDATED, Tag.TOCNV],
    tv_revenue_attributed_tracked: [Tag.DIGITAL, Tag.UPDATED, Tag.TOCNV],
    tv_total_price_attributed_tracked: [Tag.DIGITAL, Tag.UPDATED, Tag.TOCNV],
    tv_leads_attributed_tracked: [Tag.DIGITAL, Tag.UPDATED, Tag.TOCNV],
    tv_sales_attributed_tracked: [Tag.DIGITAL, Tag.UPDATED, Tag.TOCNV],
    tv_conversions_attributed_tracked: [Tag.DIGITAL, Tag.UPDATED, Tag.TOCNV],
    tv_revenue_attributed_nielsen: [Tag.DIGITAL, Tag.UPDATED, Tag.TOCNV],
    tv_total_price_attributed_nielsen: [Tag.DIGITAL, Tag.UPDATED, Tag.TOCNV],
    tv_leads_attributed_nielsen: [Tag.DIGITAL, Tag.UPDATED, Tag.TOCNV],
    tv_sales_attributed_nielsen: [Tag.DIGITAL, Tag.UPDATED, Tag.TOCNV],
    tv_conversions_attributed_nielsen: [Tag.DIGITAL, Tag.UPDATED, Tag.TOCNV],
}

export const TagsUtil = {
    /**
     * Returns the tags for a given metric name.
     *
     * @param columnName
     */
    getTags: (columnName: ColumnUniqueName): Tag[] => {
        return STATIC_TAG_ASSIGNMENTS[columnName] || computeTagAssignments(columnName)
    },
    filterMetricsByTag: (metrics: ReadonlySet<ColumnUniqueName>, tag: Tag): Set<ColumnUniqueName> => {
        return new Set(
            Array.from(metrics).filter((metric) => {
                return TagsUtil.getTags(metric).indexOf(tag) >= 0
            }),
        )
    },
    getBaseMetricDisplayName: (displayName: string): string => {
        // this list contains some customizations too, such as "Commission Model" for LHG
        return (
            [
                "Attributed",
                "Attributed Enhanced",
                "DDA",
                "FVI",
                "Digital",
                "Commission",
                "Extended",
                "DDA Enhanced",
                "DDA with Views",
                "ToCnv",
                "ToTP",
                "Original",
                "Returned",
                "Commission Model",
            ]
                .reduce((acc, tagName) => {
                    const brackets = acc.match(/\(.*\)/)
                    if (brackets && brackets.length > 0) {
                        const baseName = acc.replace(brackets[0], "")
                        const replacedBracketText = brackets[0]
                            .replace(`(${tagName},`, "(")
                            .replace(`( ${tagName},`, "(")
                            .replace(`(${tagName})`, "")
                            .replace(`( ${tagName})`, "")
                            .replace(`, ${tagName},`, ",")
                            .replace(`,${tagName},`, ",")
                            .replace(`, ${tagName})`, ")")
                            .replace(`,${tagName})`, ")")
                            .replace(`(,`, "(")
                            .replace(`(, `, "(")
                            .replace(/\(( )*/, "(")

                        return baseName + replacedBracketText
                    } else {
                        return acc
                    }
                }, displayName)
                // delete empty brackets
                .replace(/\(( )*\)/, "")
                .trim()
        )
    },
    getTagKey: (value: Tag): keyof typeof Tag => {
        return Object.keys(Tag).find((key) => Tag[key as keyof typeof Tag] === value) as keyof typeof Tag
    },
}

/**
 * Computes the tags for a given column name.
 *
 * @param columnName
 */
const computeTagAssignments = (columnName: ColumnUniqueName): Tag[] => {
    return Object.values(Tag)
        .filter((tag: string) => {
            switch (tag as Tag) {
                case Tag.ORIGINAL:
                    return columnName.indexOf("_orig") >= 0
                case Tag.RETURNED:
                    return columnName.indexOf("_returned") >= 0
                case Tag.UPDATED:
                    return (
                        (columnName.indexOf("_attributed") >= 0 &&
                            columnName.indexOf("_orig") < 0 &&
                            columnName.indexOf("_returned") < 0) ||
                        (columnName.indexOf("_commission") >= 0 &&
                            columnName.indexOf("_orig") < 0 &&
                            columnName.indexOf("_returned") < 0)
                    )
                case Tag.TOCNV:
                    return columnName.indexOf("_tocnv") >= 0
                case Tag.TOTP:
                    return columnName.indexOf("_totp") >= 0
                case Tag.COMMISSION:
                    return columnName.indexOf("_commission") >= 0
                case Tag.DIGITAL:
                    return (
                        columnName.indexOf("_attributed") >= 0 &&
                        columnName.indexOf("_extended") < 0 &&
                        columnName.indexOf("_avm") < 0
                    )
                case Tag.ENHANCED:
                    return columnName.indexOf("_extended") >= 0
                case Tag.AVM:
                    return columnName.indexOf("_avm") >= 0
            }
        })
        .map((tag) => {
            return tag as Tag
        })
}
