/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime"
import type { UserSettingsValueDTO } from "./UserSettingsValueDTO"
import {
    UserSettingsValueDTOFromJSON,
    UserSettingsValueDTOFromJSONTyped,
    UserSettingsValueDTOToJSON,
} from "./UserSettingsValueDTO"

/**
 *
 * @export
 * @interface SelectedNodeUserSettingsValueDTO
 */
export interface SelectedNodeUserSettingsValueDTO extends UserSettingsValueDTO {
    /**
     *
     * @type {number}
     * @memberof SelectedNodeUserSettingsValueDTO
     */
    advertiserId: number
    /**
     *
     * @type {number}
     * @memberof SelectedNodeUserSettingsValueDTO
     */
    campaignId?: number
}
/**
 * Check if a given object implements the SelectedNodeUserSettingsValueDTO interface.
 */
export function instanceOfSelectedNodeUserSettingsValueDTO(value: object): value is SelectedNodeUserSettingsValueDTO {
    if (!("advertiserId" in value) || value["advertiserId"] === undefined) return false
    return true
}

export function SelectedNodeUserSettingsValueDTOFromJSON(json: any): SelectedNodeUserSettingsValueDTO {
    return SelectedNodeUserSettingsValueDTOFromJSONTyped(json, false)
}

export function SelectedNodeUserSettingsValueDTOFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): SelectedNodeUserSettingsValueDTO {
    if (json == null) {
        return json
    }
    return {
        ...UserSettingsValueDTOFromJSONTyped(json, ignoreDiscriminator),
        advertiserId: json["advertiserId"],
        campaignId: json["campaignId"] == null ? undefined : json["campaignId"],
    }
}

export function SelectedNodeUserSettingsValueDTOToJSON(value?: SelectedNodeUserSettingsValueDTO | null): any {
    if (value == null) {
        return value
    }
    return {
        ...UserSettingsValueDTOToJSON(value),
        advertiserId: value["advertiserId"],
        campaignId: value["campaignId"],
    }
}
