import { NOT_AVAILABLE } from "Constants"
import { DataColumnIdentifier } from "domain/dimension/service/DimensionService"
import MetricUtil from "domain/legacy/widget/MetricUtil"
import { WidgetDimensionUtil } from "domain/legacy/widget/WidgetDimensionUtil"
import { GridDataRowDTO, MetricDTO } from "domain/types"
import { NewUiWidgetDimension, NewUiWidgetMetric, QueryWidgetSettingsDTO } from "domain/types/backend/widget.types"
import { BarSeriesOption } from "echarts"
import { EChartsOption } from "echarts-for-react/src/types"
import { CallbackDataParams } from "echarts/types/dist/shared"
import { decode } from "html-entities"
import moment from "moment"

export const OTHER = "Other"

/**
 * Iterates over [dataColumns] array and finds the data column with the identifier [dataColumnIdentifier]
 *
 * @param dataColumnIdentifier
 * @param dataColumns
 */
const getDataColumnByIdentifier = (
    dataColumnIdentifier: DataColumnIdentifier,
    dataColumns: (NewUiWidgetDimension | NewUiWidgetMetric)[],
): NewUiWidgetDimension | NewUiWidgetMetric => {
    return dataColumns.find((dataColumn) => dataColumn.identifier === dataColumnIdentifier)
}

/**
 * Concat 'Other ' and the [title]
 *
 * @param dimensionDTO
 */
const getOtherTitle = (dimensionDTO: NewUiWidgetDimension): string => {
    return `${OTHER} ${WidgetDimensionUtil.getDimensionPlural(dimensionDTO)}`
}

/**
 * Combines ui settings and widget series data
 *
 * @param echartsOption
 * @param formatter
 * @param xValues
 * @param allSeriesWithUiSettings
 * @param legend
 * @param metric - metric of the y axis
 */
const combineUiSettingsAndWidgetData = (
    echartsOption: EChartsOption,
    formatter: (item: CallbackDataParams) => string,
    xValues: string[],
    allSeriesWithUiSettings: BarSeriesOption[],
    legend: (string | { name: string; itemStyle: { color: any } })[],
    metric: MetricDTO,
): EChartsOption => {
    return {
        ...echartsOption,
        tooltip: {
            ...echartsOption.tooltip,
            formatter: formatter,
        },
        xAxis: { ...echartsOption.xAxis, data: formatItemNames(xValues) },
        series: allSeriesWithUiSettings,
        legend: { ...echartsOption.legend, data: legend },
        yAxis: {
            ...echartsOption.yAxis,
            name: metric.displayName,
            axisLabel: {
                ...echartsOption.yAxis.axisLabel,
                formatter: MetricUtil.metricValueFormatter(metric, true),
            },
        },
    }
}

/**
 * Formats widget item names:
 * - if empty, then N/A
 * - decodes html entities
 *
 * @param itemNames
 */
const formatItemNames = (itemNames: string[]): string[] => itemNames.map(formatItemName)

/**
 * Formats widget item name:
 * - if empty, then N/A
 * - decodes html entities
 *
 * @param itemName
 */
const formatItemName = (itemName: string): string => decode(itemName ? itemName : NOT_AVAILABLE)

/**
 * Write the data from 'yAxis' to 'xAxis' and vice versa,
 * used to switch bar direction between vertical and horizontal.
 *
 * @param echartsOption
 */
const swapXAndYAxis = (echartsOption: EChartsOption) => {
    const yAxis = echartsOption["yAxis"]
    echartsOption["yAxis"] = echartsOption["xAxis"]
    echartsOption["xAxis"] = yAxis
    echartsOption["yAxis"].inverse = true
}

/**
 * Gets [columnIdentifier] values from the [dataSet] and sort them by [orderByMetricIdentifier] descending
 *
 * @param columnIdentifier
 * @param rows
 * @param orderByMetricIdentifier
 */
const getColumnValuesOrderedByMetricDesc = (
    columnIdentifier: DataColumnIdentifier,
    rows: GridDataRowDTO[],
    orderByMetricIdentifier: DataColumnIdentifier,
) => {
    return orderByMetricDesc(
        columnIdentifier,
        getUniqueValues(columnIdentifier, rows).map((value) => (value == undefined ? NOT_AVAILABLE : value)),
        rows,
        orderByMetricIdentifier,
    )
}

/**
 * Get values for a certain column out of a dataset and make sure they are unique
 *
 * @param columnName
 * @param rows
 */
const getUniqueValues = (columnName: DataColumnIdentifier, rows: GridDataRowDTO[]): any[] => {
    return rows
        .map((row) => (row[columnName]?.name ? row[columnName]?.name : row[columnName]?.value))
        .filter((value, index, self) => {
            return self.indexOf(value) === index
        })
}

/**
 * Sort the dimension list by the biggest metric value
 *
 * @param columnIdentifier
 * @param list
 * @param rows
 * @param metric
 */
const orderByMetricDesc = (
    columnIdentifier: DataColumnIdentifier,
    list: string[],
    rows: GridDataRowDTO[],
    metric: DataColumnIdentifier,
): string[] => {
    return [...list].sort((a, b) => {
        const sumA = sumMetricValuesForTheColumnValue(rows, columnIdentifier, a, metric)
        const sumB = sumMetricValuesForTheColumnValue(rows, columnIdentifier, b, metric)

        return sumA >= sumB ? -1 : 1
    })
}

/**
 * Summarize the [metricIdentifier] values of all rows, where [columnIdentifier] value is equal to [columnValue]:
 * when rows= [{campaign: {value: 825}, clicks: {value: 2}}, {campaign: {value: 825}, clicks: {value: 4}, , {campaign: {value: 821}, clicks: {value: 5}}],
 * then sumMetricValuesForTheColumnValue(rows, 'campaign', 825, 'clicks') returns 2+4=6
 *
 * @param rows
 * @param columnIdentifier
 * @param columnValue
 * @param metricIdentifier
 */
const sumMetricValuesForTheColumnValue = (
    rows: GridDataRowDTO[],
    columnIdentifier: DataColumnIdentifier,
    columnValue: any,
    metricIdentifier: DataColumnIdentifier,
) => {
    return rows.reduce(
        (acc, row) =>
            acc +
            (getNameOrValueOrNA(row, columnIdentifier) === columnValue ? (row[metricIdentifier]?.value as number) : 0),
        0,
    )
}

/**
 * Extracts name from the row for the key. If name not there, then returns value.
 * If value is not there, then returns N/A
 *
 * @param row
 * @param key
 */
const getNameOrValueOrNA = (row: GridDataRowDTO, key: DataColumnIdentifier): any => {
    return row[key]?.name ? row[key]?.name : row[key]?.value ? row[key]?.value : NOT_AVAILABLE
}

/**
 * Checks whether [rows] contain some row where the metric value is negative
 *
 * @param rows
 * @param metricDTO
 */
const containsNegativeValues = (rows: GridDataRowDTO[], metricDTO: MetricDTO): boolean => {
    return rows.some((row) => row[metricDTO.identifier] && (row[metricDTO.identifier]?.value as number) < 0)
}

/**
 * Finds in the rows the row with [firstColumnValue] and [secondColumnValue] for [firstColumnIdentifier] and [secondColumnIdentifier]
 *
 * @param rows
 * @param firstColumnIdentifier
 * @param firstColumnValue
 * @param secondColumnIdentifier
 * @param secondColumnValue
 */
const findRow = (
    rows: GridDataRowDTO[],
    firstColumnIdentifier: DataColumnIdentifier,
    firstColumnValue: any,
    secondColumnIdentifier: DataColumnIdentifier,
    secondColumnValue: any,
): GridDataRowDTO => {
    return rows.find((row) => {
        const rowFirstValue = row[firstColumnIdentifier].name
            ? row[firstColumnIdentifier].name
            : row[firstColumnIdentifier].value
        const rowSecondValue = row[secondColumnIdentifier].name
            ? row[secondColumnIdentifier].name
            : row[secondColumnIdentifier].value

        return (
            notAvailableToUndefined(rowFirstValue) === notAvailableToUndefined(firstColumnValue) &&
            notAvailableToUndefined(rowSecondValue) === notAvailableToUndefined(secondColumnValue)
        )
    })
}

/**
 * Extracts [WidgetSettingsDTO] from 'widgetSettings' url parameter
 *
 * @param href
 */
const extractWidgetSettingsFromUrl = (href: string): QueryWidgetSettingsDTO => {
    return JSON.parse(new URL(href).searchParams.get("widgetSettings")) as QueryWidgetSettingsDTO
}

/**
 * Replaces the ZonedDateTime format ':00Z[UTC]' with the LocalDateTime format ':00:00' in the widget settings
 * @param widgetSettings
 */
const sanitizeWidgetSettings = (widgetSettings: QueryWidgetSettingsDTO): QueryWidgetSettingsDTO => {
    const inputDateFormat = "YYYY-MM-DDTHH:mm"
    const outputDateFormat = "YYYY-MM-DDTHH:mm:ss"

    return {
        ...widgetSettings,
        querySettings: {
            ...widgetSettings.querySettings,
            timespanSettings: {
                ...widgetSettings.querySettings.timespanSettings,
                start: moment(widgetSettings.querySettings.timespanSettings.start, inputDateFormat).format(
                    outputDateFormat,
                ),
                end: moment(widgetSettings.querySettings.timespanSettings.end, inputDateFormat).format(
                    outputDateFormat,
                ),
            },
        },
    }
}

/**
 * Converts 'N/A' to undefined, otherwise returns the same value
 *
 * @param value
 */
const notAvailableToUndefined = (value: any): any => {
    return value == NOT_AVAILABLE ? undefined : value
}

const WidgetDataUtil = {
    getUniqueValues: getUniqueValues,
    orderByMetricDesc: orderByMetricDesc,
    getColumnValuesOrderedByMetricDesc: getColumnValuesOrderedByMetricDesc,
    getOtherTitle: getOtherTitle,
    swapXAndYAxis: swapXAndYAxis,
    combineUiSettingsAndWidgetData: combineUiSettingsAndWidgetData,
    findRow: findRow,
    containsNegativeValues: containsNegativeValues,
    extractWidgetSettingsFromUrl: (href: string) => sanitizeWidgetSettings(extractWidgetSettingsFromUrl(href)),
    getDataColumnByIdentifier: getDataColumnByIdentifier,
    formatItemName: formatItemName,
    getNameOrValueOrNA: getNameOrValueOrNA,
    sanitizeWidgetSettings: sanitizeWidgetSettings,
}

export default WidgetDataUtil
