import { GridDataRowDTO } from "domain/types"
import { NewUiWidgetDimension } from "domain/types/backend/widget.types"

/**
 * Creates title from mainDimension
 *
 * @param items
 * @param mainDimension
 */
const getMainDimensionTitle = (items: GridDataRowDTO[], mainDimension: NewUiWidgetDimension): string => {
    return items.length === 1 ? mainDimension.displayName : getDimensionPlural(mainDimension)
}

/**
 * Returns displayNamePlural if it exists, otherwise displayName plus 's'
 * @param dimension
 */
const getDimensionPlural = (dimension: NewUiWidgetDimension): string => {
    return dimension.displayNamePlural ? dimension.displayNamePlural : dimension.displayName + "s"
}

/**
 * Return nameColumn if it exists, otherwise identifier as fallback
 */
const getNameColumn = (dimension: NewUiWidgetDimension): string => {
    // TODO: there is no nameColumn in the DTO
    return dimension.nameColumn ? dimension.nameColumn : dimension.identifier
    // return dimension.identifier
}

export const WidgetDimensionUtil = {
    getMainDimensionTitle: getMainDimensionTitle,
    getNameColumn: getNameColumn,
    getDimensionPlural: getDimensionPlural,
}
