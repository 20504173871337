import { Box, Typography } from "@mui/material"
import { BoxProps } from "@mui/material/Box/Box"
import { styled } from "@mui/material/styles"
import React from "react"
import { Link } from "react-router"

const StyledFooterLinks = styled("div")({
    padding: 8,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& ul": {
        listStyleType: "none",
        margin: 0,
        padding: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& li": {
            marginRight: "10px",
            "&:last-child": {
                marginRight: 0,
            },
            "& a": {
                fontSize: 12,
                color: "inherit",
                textDecoration: "none",
            },
        },
    },
})

function generateIdFromUrl(url: string): string {
    return url
        .replace(/^https?:\/\//, "")
        .replace(/[^a-z0-9]/gi, "-")
        .toLowerCase()
}

const FooterLinkItem = ({ link, children }) => (
    <li key={generateIdFromUrl(link)}>
        <Typography component={Link} to={link}>
            {children}
        </Typography>
    </li>
)

export default function FooterLinks(props: BoxProps) {
    return (
        <Box {...props}>
            <StyledFooterLinks>
                <ul>
                    <FooterLinkItem link={"https://exactag.com/terms-and-conditions-for-ui-and-help-center"}>
                        Terms of Service
                    </FooterLinkItem>
                    <FooterLinkItem link={"https://exactag.com/privacy-policy-for-ui-and-help-center"}>
                        Privacy Policy
                    </FooterLinkItem>
                    <FooterLinkItem link={"https://www.exactag.com/imprint/"}>Legal Notice</FooterLinkItem>
                </ul>
            </StyledFooterLinks>
        </Box>
    )
}
