import { ActionType } from "domain/types"
import moment, { Moment } from "moment"

export const formDateFormat = "YYYY-MM-DD"

/**
 * Groups the list by the closure getKey. Returns a map with the key as the result of getKey(item) and regarding
 * item list.
 *
 * @param list
 * @param getKey
 */
export const groupBy = (list: any[], getKey: (item: any) => any): Map<any, any[]> => {
    const map = new Map<any, any[]>()
    list.forEach((item) => {
        const key = getKey(item)
        const collection = map.get(key)
        if (!collection) {
            map.set(key, [item])
        } else {
            collection.push(item)
        }
    })

    return map
}

export const capitalizeEnum = (text: string): string => {
    return (
        (text &&
            typeof text === "string" &&
            text
                .toLowerCase()
                .split("_")
                .map((part) => `${part.charAt(0).toUpperCase()}${part.substr(1)}`)
                .join(" ")) ||
        ""
    )
}

export const dateString = (mmnt: Moment, format?: string) =>
    moment.parseZone(mmnt).format(format ? format : formDateFormat)

export const euroBudgetInputFormatter = (value) => `€ ${value || 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")

export const numberInputParser = (value, currencySymbol = "€") => {
    let result: number
    switch (currencySymbol) {
        case "$":
            result = Number(`${value}`.replace(/\$\s?|(\.*)/g, ""))
            break
        case "€":
            result = Number(`${value}`.replace(/€\s?|(\.*)/g, ""))
            break
    }

    return isNaN(result) ? 0 : Math.max(0, result)
}

export const getStatusId = (type: string): number => {
    switch (type) {
        case ActionType.DEACTIVATE:
            return 0
        case ActionType.ACTIVATE:
            return 1
        case ActionType.DELETE:
            return 3
        default:
            return undefined
    }
}

export const isJsonObject = (source: string): boolean => {
    try {
        JSON.parse(source)
    } catch (e) {
        return false
    }
    return true
}

export const toCamelCase = (s: string) => {
    return s.replace(/([-_][a-z])/gi, ($1) => {
        return $1.toUpperCase().replace("-", "").replace("_", "")
    })
}
