import { MetricDTO } from "domain/types"
import numbro from "numbro"
import NumberFormatter from "shared/util/NumberFormatter"

const MetricUtil = {
    /**
     * Generates number value formatter for the [metric].
     * The formatter applies metric format configs (such as prefix, postfix, round) for the [value].
     *
     * @param metric
     * @param useShortNumberFormat - if true, then e.g. 10000 -> 10k (used for axis labels)
     */
    metricValueFormatter:
        (metric: MetricDTO, useShortNumberFormat: boolean = false) =>
        (value: any): string => {
            const metricPrefix = metric.unitPrefix ? metric.unitPrefix : ""
            const metricPostfix = metric.unitPostfix ? metric.unitPostfix : ""

            let formattedNumber
            if (useShortNumberFormat) {
                // we take hardcoded 1 digit for the shortened number format
                formattedNumber = NumberFormatter.shortenNumber(value, 1)
            } else {
                formattedNumber = numbro(value).format({
                    thousandSeparated: true,
                    mantissa: metric.round,
                    trimMantissa: false,
                })
            }

            return `${metricPrefix}${formattedNumber}${metricPostfix}`
        },
}

export default MetricUtil
