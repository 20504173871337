import messageListener from "domain/messaging/MessageListener"
import { log } from "shared/util/log"
import { isJsonObject } from "shared/util/util"

const browserWindow: any = window || {}
const easyXDM = browserWindow.easyXDM || {}

declare global {
    interface Window {
        transport?: any
    }
}

const EmbeddingUtil = {
    /**
     * Returns true when referrer contains embedded=true
     *
     * @param url
     */
    isEmbedded: (url: string) => {
        return url && url.indexOf("embedded=true") >= 0
    },

    /**
     * Returns true if one of the following applies:
     * - GET parameter embedded=true has been appended to the URL (this triggers easyXdm setup)
     * - GET parameter simulateEmbedded=true has been appended to the URL (this is useful for local layout testing without triggering easyXdm)
     * - the page has been embedded in an iFrame
     */
    isCurrentLocationEmbedded: (): any => {
        const url = new URL(window.location.href)
        const embeddedParam = url.searchParams.get("embedded")
        const simulateEmbeddedParam = url.searchParams.get("simulateEmbedded")
        const isInIframe = window != window.top
        return isInIframe || embeddedParam === "true" || simulateEmbeddedParam === "true"
    },

    /**
     * Returns true when referrer contains isSimulateEmbedded=true
     *
     * @param referrer
     */
    isSimulateEmbedded: (referrer: string) => {
        return referrer && referrer.indexOf("simulateEmbedded=true") >= 0
    },

    initializeEasyXdm: (baseUrlFrontend: string) => {
        const config = {
            isHost: false,
            local: `${baseUrlFrontend}/asset/js/easyXDM/name.html`,
            onMessage: function (message, origin) {
                log.debug("Received the following message: ", message, origin)

                messageListener.onMessage(isJsonObject(message) ? JSON.parse(message) : message)
            },
        }

        const transport = new easyXDM.Socket(config)

        // save [transport] in the browser window, so that we can later send messages to the host container
        browserWindow.transport = transport

        // inform the embedder that the user has been logged in
        // TODO: use "sendMessage" methode with type specification
        transport.postMessage("logInSuccess")
    },

    /**
     * Sends [message] of [type] to the host component
     *
     * @param type
     * @param message
     */
    sendMessage: (type: string, message: any) => {
        if (!window.transport) {
            return
        }
        window.transport.postMessage(
            JSON.stringify({
                type: type,
                body: message,
            }),
        )
    },
}
export default EmbeddingUtil
