import jsSHA from "jssha"

const PasswordService = {
    //FIXME this simulates the MySQL `PASSWORD` function and must be improved once the passwords are stored differently
    hash: (password: string): string => {
        const hash1 = new jsSHA("SHA-1", "TEXT")
        hash1.update(password)
        const hash2 = new jsSHA("SHA-1", "HEX")
        hash2.update(hash1.getHash("HEX"))

        return "*" + hash2.getHash("HEX", { outputUpper: true })
    },
}

export default PasswordService
