import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RestApiLogonDTO } from "generated/models"

export type AuthenticationState = RestApiLogonDTO & {
    loggingIn?: boolean
    loginError?: string
}

const initialState: AuthenticationState = {
    loggingIn: false,
    loggedInViaFirstFactor: false,
    loggedInViaSecondFactor: false,
    consentGranted: false,
    user: undefined,
    redirectUri: "",
}

const authenticationSlice = createSlice({
    name: "authentication",
    initialState,
    reducers: {
        loggingIn(state: AuthenticationState): void {
            applyTo(state, initialState)
            state.loggingIn = true
        },
        loginProcess(state: AuthenticationState, action: PayloadAction<RestApiLogonDTO>): void {
            applyTo(state, action.payload)
            state.loggingIn = !(action.payload.loggedInViaSecondFactor && action.payload.consentGranted)
        },
        loginFailure(state: AuthenticationState, action: PayloadAction<{ error: string }>): void {
            applyTo(state, initialState)
            state.loginError = action.payload?.error
        },
    },
})

/**
 * Apply the properties of objectToApply to state
 *
 * @param state
 * @param objectToApply
 */
const applyTo = (state: AuthenticationState, objectToApply: AuthenticationState | RestApiLogonDTO) => {
    Object.keys(objectToApply).forEach((key) => {
        state[key] = objectToApply[key]
    })
}

export const { loggingIn, loginProcess, loginFailure } = authenticationSlice.actions

export default authenticationSlice.reducer
