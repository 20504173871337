import PasswordService from "domain/password/service/PasswordService"
import { LoginResponseDTO, RestApiLogonDTO } from "generated/models"
import { get, post } from "shared/service"
import UrlService from "shared/service/url.service"

const logon = (referrer: string = "/"): Promise<RestApiLogonDTO> => {
    return get(UrlService.getUserServiceApiUrl() + "/auth/logon?referrer=" + encodeURI(referrer))
}
const login = (username: string, password: string): Promise<LoginResponseDTO> => {
    // increase timeout to 60 seconds, because the login process can take a while if the UIRs are not generated yet
    return post(
        `${UrlService.getUserServiceApiUrl()}/login`,
        { username: username, passwordHashed: PasswordService.hash(password) },
        { timeout: 60000 },
    )
}

const logout = () => {
    return get(UrlService.getUserServiceApiUrl() + "/auth/invalidate")
}

const AuthenticationService = {
    logon: logon,
    login: login,
    logout: logout,
}

export default AuthenticationService
