import { CookieUtil } from "shared/util/CookieUtil"

export const CsrfFetchUtil = {
    /**
     * Create a RequestInit object where the XSRF token is copied from the cookie to the header.
     */
    getCsrfJsonHeaders: (): RequestInit => {
        return {
            // copy the XSRF token from the cookie to the header
            headers: {
                "X-XSRF-TOKEN": CookieUtil.getCookie("XSRF-TOKEN"),
                "Content-Type": "application/json",
            },
        }
    },
}
