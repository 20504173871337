import { CircularProgress } from "@mui/material"
import { loginFailure, loginProcess } from "domain/authentication/redux/authentication.slice"
import { authenticationService } from "domain/authentication/service"
import { NotificationType } from "domain/notification/NotificationService"
import { notificationActions } from "domain/notification/redux/load.notification.actions"
import { passwordExpirationActions } from "domain/password/redux/load.password.expiration.actions"
import { actions as userActions } from "domain/user/redux/load.user.data.actions"
import { UserSettingsService } from "domain/user/settings/service/UserSettingsService"
import PublicLayout from "layout/PublicLayout/PublicLayout"
import * as React from "react"
import { type JSX, useEffect } from "react"
import { useSelector } from "react-redux"
import { useLocation, useSearchParams } from "react-router"
import { useNavigationContext } from "shared/NavigationContext"
import { useAnalytics } from "shared/analytics/AnalyticsContext"
import { RootState, useAppDispatch } from "shared/redux/store"
import EmbeddingUtil from "shared/util/EmbeddingUtil"
import { log } from "shared/util/log"

declare let baseUrlFrontend: string

const LogonPage = (): JSX.Element => {
    const [searchParams] = useSearchParams()
    const location = useLocation()
    const dispatch = useAppDispatch()

    const navigationContext = useNavigationContext()
    const analyticsService = useAnalytics()
    const loggingIn = useSelector((state: RootState) => state.authentication.loggingIn)

    useEffect(() => {
        const referrer = location.state?.referrer ?? searchParams.get("referrer") ?? "/ui"

        logon(referrer)
        // TODO: is it safe to add the missing dependencies?
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const logon = (referrer: string = "/") => {
        authenticationService
            .logon(referrer)
            .then((response) => {
                dispatch(loginProcess(response))

                const firstFactorAccessibleRoutes = ["/ui/login", "/ui/consent", "/ui/mfa/otp/verify-code"]
                const pointsToFirstFactorAccessibleRoute = (route: string) =>
                    firstFactorAccessibleRoutes.some((path) => route.indexOf(path) >= 0)

                if (response.redirectUri && pointsToFirstFactorAccessibleRoute(response.redirectUri)) {
                    // find the route for the redirect
                    const route = firstFactorAccessibleRoutes.find((route) => response.redirectUri.indexOf(route) >= 0)
                    navigationContext.navigate(route, { state: { referrer: referrer === route ? "/ui" : referrer } })
                    return
                }

                // when loading widget data, no need to load menu and all user settings
                if (referrer.startsWith("/ui/widget") || referrer.startsWith("/ui/embeddingtest")) {
                    const isEmbedded = EmbeddingUtil.isEmbedded(referrer)
                    const isSimulateEmbedded = EmbeddingUtil.isSimulateEmbedded(referrer)

                    if (isEmbedded && !isSimulateEmbedded) {
                        EmbeddingUtil.initializeEasyXdm(baseUrlFrontend)
                    }

                    navigationContext.navigate(referrer)
                } else if (pointsToFirstFactorAccessibleRoute(referrer)) {
                    navigationContext.navigate(referrer)
                } else {
                    passwordExpirationActions.loadPasswordExpiration(!response.user.internalUser)(dispatch)

                    // promises, for that we will wait to execute some actions
                    // even though we do not display the manu in the embedded mode we still need it in both cases in order for navigation to work
                    const loadMenuPromise = userActions.loadUserMenu(dispatch)

                    const loadTreePromise = userActions.loadUserTree(dispatch)
                    const loadUserSettingsPromise = UserSettingsService.loadSelectedNodeUserSetting()
                    const loadUserInfoPromise = userActions.loadUserInfo(dispatch)

                    Promise.all([loadTreePromise, loadUserSettingsPromise]).then(([userTree, userSettings]) => {
                        const appContext = userActions.createAndStoreAppContext(userTree, userSettings, dispatch)
                        if (appContext) {
                            notificationActions.loadNotificationCount(NotificationType.ALERTS)(dispatch)
                            notificationActions.loadNotificationCount(NotificationType.NEWS)(dispatch)
                        } else {
                            log.warn(`AppContext is undefined`)
                        }

                        const trackLoginEvent =
                            loggingIn && response.loggedInViaFirstFactor && response.loggedInViaSecondFactor
                        analyticsService
                            .initialize(trackLoginEvent)
                            .catch((err) => log.warn("Error while initializing analytics", err))
                    })

                    Promise.all([loadMenuPromise, loadTreePromise, loadUserSettingsPromise, loadUserInfoPromise]).then(
                        () => {
                            navigationContext.navigate(referrer)
                        },
                    )
                }
            })
            .catch((error: any) => dispatch(failure(error)))
    }

    const failure = (error: any) => {
        if (typeof error.message === "string") {
            log.error(error.message)
        }
        if (typeof error.stack === "string") {
            log.error(error.stack)
        }

        let errorLog: string
        if (typeof error === "string") {
            errorLog = error
        } else if (typeof error.message === "string") {
            errorLog = error.message
        } else {
            errorLog = error.toString()
        }

        navigationContext.navigate("/ui/login")

        return loginFailure({ error: errorLog })
    }

    if (loggingIn) {
        return (
            <PublicLayout showLogo={true} showFooter={true}>
                {<CircularProgress />}
            </PublicLayout>
        )
    } else {
        return (
            <div className="screen-centered">
                <CircularProgress />
            </div>
        )
    }
}

export default LogonPage
