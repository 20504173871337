import { GetUsersQueryHook, SwitchUserMutationHook } from "./userswitch.api"
import { createApi } from "@reduxjs/toolkit/query/react"
import { RegularUserDTO } from "generated/models"
import { axiosBaseQuery } from "shared/redux/axiosBaseQuery"
import UrlService from "shared/service/url.service"

interface RegularUsersResponseDTO {
    users: RegularUserDTO[]
}

interface UserSwitchRequestDTO {
    userId: number
}

interface UserSwitchResponseDTO {
    successful: boolean
}

export const userSwitchApi = createApi({
    reducerPath: "userSwitchApi",
    baseQuery: axiosBaseQuery({ baseUrl: `${UrlService.getUserServiceApiUrl()}/internal/` }),
    endpoints: (builder) => ({
        getUsers: builder.query<RegularUserDTO[], void>({
            query: () => ({
                url: "getAllActiveRegularUsers",
                method: "GET",
            }),
            transformResponse: (response: RegularUsersResponseDTO) => response.users,
        }),
        switchUser: builder.mutation<boolean, number>({
            query: (userId) => ({
                url: "userSwitch",
                method: "POST",
                data: { userId: userId } as UserSwitchRequestDTO,
            }),
            transformResponse: (response: UserSwitchResponseDTO) => response.successful,
        }),
    }),
})

export const useGetUsersQuery: GetUsersQueryHook = (skip = false) => {
    const { data, isLoading, isError } = userSwitchApi.useGetUsersQuery(undefined, { skip })
    return { users: data, isLoading, isError }
}

export const useSwitchUserMutation: SwitchUserMutationHook = () => {
    const [switchUser] = userSwitchApi.useSwitchUserMutation()

    return (userId: number): Promise<boolean> => {
        return switchUser(userId).unwrap()
    }
}
