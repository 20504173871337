// General type identifying DTOs with an ID of type number.
import { ColumnResponseDTO } from "generated/models"

export type DataColumnDTO = {
    identifier: string
    displayName: string
}
export type MetricDTO = DataColumnDTO & {
    computationType: ComputationType
    round: number
    type: MetricType
    unitPostfix?: string
    unitPrefix?: string
}

export type DataSliceDTO = {
    totals: { [key: string]: any }
    maxValues: { [key: string]: any }
    minValues: { [key: string]: any }
    slice: Array<{ [key: string]: any }>
    totalResults: number
    pages: number
}

/**
 * Computation type of the metric
 */
export enum ComputationType {
    CT_SUM = "CT_SUM",
    CT_AVERAGE = "CT_AVERAGE",
    CT_NONE = "CT_NONE",
}

/**
 * Type of the metric value
 */
export enum MetricType {
    INT = "int",
    FLOAT = "float",
    STRING = "str",
    DATE = "date",
}

export type OrderDirection = "ASC" | "DESC"

export type GridDataRowDTO = { [key: string]: ColumnResponseDTO }

export type DataRowDTO = { [key: string]: any }
