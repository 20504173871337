import { DimensionMetricCompatibility } from "domain/ColumnConfigurator/DimensionMetricCompatibility"
import { ColumnUniqueName } from "domain/ColumnConfigurator/types"

export class SelectedState {
    widgetId: number | undefined
    selectedDimensions: DimensionIdentifiableSettings[]
    selectedMetrics: MetricIdentifiableSettings[]
    leftPinnedMetrics: ColumnUniqueName[]

    constructor({
        widgetId,
        selectedDimensions,
        selectedMetrics,
        leftPinnedMetrics,
    }: {
        widgetId: number | undefined
        selectedDimensions: DimensionIdentifiableSettings[]
        selectedMetrics: MetricIdentifiableSettings[]
        leftPinnedMetrics: ColumnUniqueName[]
    }) {
        this.widgetId = widgetId
        this.selectedDimensions = selectedDimensions ?? []
        this.selectedMetrics = selectedMetrics ?? []
        this.leftPinnedMetrics = leftPinnedMetrics ?? []
    }

    /**
     * Returns the selected metrics filtered by the given dimensionMetricCompatibility
     *
     * @param dimensionMetricCompatibility
     */
    getCompatibleSelectedMetrics(dimensionMetricCompatibility: DimensionMetricCompatibility) {
        return this.selectedMetrics.filter((metric) =>
            dimensionMetricCompatibility.isMetricCompatibleWithSelectedDimensions(metric.identifier),
        )
    }

    /**
     * Returns the supported state for the given dimensionMetricCompatibility
     *
     * @param dimensionMetricCompatibility
     */
    getSupportedState(dimensionMetricCompatibility: DimensionMetricCompatibility): SelectedState {
        return {
            ...this,
            selectedMetrics: this.getCompatibleSelectedMetrics(dimensionMetricCompatibility),
        }
    }
}

export interface IdentifiableSettings {
    identifier: ColumnUniqueName
}

export interface DimensionIdentifiableSettings extends IdentifiableSettings {}

export interface MetricIdentifiableSettings extends IdentifiableSettings {
    showBars: boolean
}

/**
 * Action to perform on a column
 */
export type ColumnSelectAction = "add" | "delete" | "toggle"
