import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { QueryWidgetSettingsDTO, WidgetSettingsDTO, WidgetType } from "domain/types/backend/widget.types"

export type WidgetSettingsState = {
    widgetSettings?: WidgetSettingsDTO
}

const initialState: WidgetSettingsState = {
    widgetSettings: undefined,
}

const widgetSlice = createSlice({
    name: "widget",
    initialState,
    reducers: {
        storeWidgetSettings(
            state: WidgetSettingsState,
            action: PayloadAction<{ widgetSettings: WidgetSettingsDTO }>,
        ): void {
            state.widgetSettings = action.payload.widgetSettings
        },
    },
})

export const { storeWidgetSettings } = widgetSlice.actions

export default widgetSlice.reducer
