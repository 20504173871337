import numbro from "numbro"

numbro.registerLanguage({
    ...numbro.languageData("en-US"),
    languageTag: "en-DE",
    delimiters: {
        thousands: ".",
        decimal: ",",
    },
    currency: {
        symbol: "€",
        position: "postfix",
        code: "EUR",
    },
})
numbro.setLanguage("en-DE")
numbro.zeroFormat("0")

/**
 * Formats a number as "million" with 1 decimal place if the number is not a full million
 * or without a decimal place if the number is a multiple of 1 million.
 * @example
 * formatAsMillions(1000000) // "1 million"
 * formatAsMillions(1100000) // "1.1 million"
 */
export function formatAsMillions(value: number): string {
    const millions = value / 1_000_000

    if (value % 1_000_000 === 0) {
        return `${Math.floor(millions)} million`
    }

    return `${millions.toLocaleString("de-DE", { maximumFractionDigits: 1, minimumFractionDigits: 1 })} million`
}

const NumberFormatter = {
    /**
     * Formats number with the thousand and more suffix
     * (e.g 2500 -> 2,5k)
     *
     * @param number
     * @param digits
     */
    shortenNumber: (number: number, digits: number): string => {
        const lookup = [
            { value: 1, symbol: "" },
            { value: 1e3, symbol: "k" },
            { value: 1e6, symbol: "m" },
            { value: 1e9, symbol: "b" },
            { value: 1e12, symbol: "t" },
            { value: 1e15, symbol: "p" },
            { value: 1e18, symbol: "e" },
        ]
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
        const item = lookup
            .slice()
            .reverse()
            .find((item) => Math.abs(number) >= item.value)
        return item ? (number / item.value).toFixed(digits).replace(rx, "$1").replace(".", ",") + item.symbol : "0"
    },

    formatNumberPercent: (round: number, value: any) => {
        return numbro(value || 0).format({ output: "percent", mantissa: round })
    },
    format: (number: number): string => {
        return numbro(number).format({ thousandSeparated: true })
    },

    formatAsMillions: formatAsMillions,
}

export default NumberFormatter
