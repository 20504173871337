declare const gatewayUrl: string
declare const dashboardApiUrl: string
declare const classicUiUrl: string

const UrlService = {
    getGatewayUrl: () => {
        return gatewayUrl
    },

    getUiServiceApiUrl: () => {
        return `${gatewayUrl}/ui`
    },

    getUiContextPath: () => {
        return "/ui"
    },

    getFilterServiceApiUrl: () => {
        return `${gatewayUrl}/api/filter`
    },

    getUserServiceApiUrl: () => {
        return `${gatewayUrl}/api/user`
    },

    getReportingServiceApiUrl: () => {
        return `${gatewayUrl}/api/reporting`
    },

    getDashboardApiUrl: () => {
        return dashboardApiUrl
    },

    getClassicUiUrl: () => {
        return classicUiUrl
    },
}

export default UrlService
