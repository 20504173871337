/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime"
import type { UserSettingsValueDTO } from "./UserSettingsValueDTO"
import {
    UserSettingsValueDTOFromJSON,
    UserSettingsValueDTOFromJSONTyped,
    UserSettingsValueDTOToJSON,
} from "./UserSettingsValueDTO"

/**
 *
 * @export
 * @interface GridColumnStateSettingsValueDTO
 */
export interface GridColumnStateSettingsValueDTO extends UserSettingsValueDTO {
    /**
     *
     * @type {Array<string>}
     * @memberof GridColumnStateSettingsValueDTO
     */
    pinnedColumnIdentifiers: Array<string>
    /**
     *
     * @type {Array<string>}
     * @memberof GridColumnStateSettingsValueDTO
     */
    selectedColumnIdentifiers: Array<string>
}
/**
 * Check if a given object implements the GridColumnStateSettingsValueDTO interface.
 */
export function instanceOfGridColumnStateSettingsValueDTO(value: object): value is GridColumnStateSettingsValueDTO {
    if (!("pinnedColumnIdentifiers" in value) || value["pinnedColumnIdentifiers"] === undefined) return false
    if (!("selectedColumnIdentifiers" in value) || value["selectedColumnIdentifiers"] === undefined) return false
    return true
}

export function GridColumnStateSettingsValueDTOFromJSON(json: any): GridColumnStateSettingsValueDTO {
    return GridColumnStateSettingsValueDTOFromJSONTyped(json, false)
}

export function GridColumnStateSettingsValueDTOFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): GridColumnStateSettingsValueDTO {
    if (json == null) {
        return json
    }
    return {
        ...UserSettingsValueDTOFromJSONTyped(json, ignoreDiscriminator),
        pinnedColumnIdentifiers: json["pinnedColumnIdentifiers"],
        selectedColumnIdentifiers: json["selectedColumnIdentifiers"],
    }
}

export function GridColumnStateSettingsValueDTOToJSON(value?: GridColumnStateSettingsValueDTO | null): any {
    if (value == null) {
        return value
    }
    return {
        ...UserSettingsValueDTOToJSON(value),
        pinnedColumnIdentifiers: value["pinnedColumnIdentifiers"],
        selectedColumnIdentifiers: value["selectedColumnIdentifiers"],
    }
}
