import { Configuration } from "generated/runtime"
import UrlService from "shared/service/url.service"

export const ServiceConfigs = {
    uiBackendServiceConfig: new Configuration({ basePath: UrlService.getUiServiceApiUrl(), credentials: "include" }),
    uiReportingServiceConfig: new Configuration({
        basePath: UrlService.getReportingServiceApiUrl(),
        credentials: "include",
    }),
    uiFilterServiceConfig: new Configuration({ basePath: UrlService.getFilterServiceApiUrl(), credentials: "include" }),
    uiUserServiceConfig: new Configuration({ basePath: UrlService.getUserServiceApiUrl(), credentials: "include" }),
}
