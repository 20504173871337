import { log } from "shared/util/log"

export interface AnalyticsService {
    /**
     * Initializes the analytics service. Must be called before any other method.
     *
     * @param trackLoginEvent if true, a login event is tracked.
     */
    initialize(trackLoginEvent: boolean): Promise<void>

    /**
     * Tracks a menu navigation event.
     *
     * @param rootNode The root node of the path. Example: `Setup`
     * @param path The path of the menu item. Example: `Setup / Campaign Management / Channels`
     */
    trackMenuNavigation(rootNode: string, path: string)

    /**
     * Tracks a button click event.
     *
     * @param buttonName The name of the button that was clicked.
     */
    trackButtonClick(buttonName: string)

    /**
     * Tracks a custom event.
     * @param eventName
     * @param eventData
     */
    trackCustomEvent(eventName: string, eventData: object)
}

export const noopAnalyticsService = new (class implements AnalyticsService {
    async initialize(trackLoginEvent: boolean): Promise<void> {
        log.info(`noopAnalyticsService.initialize(${trackLoginEvent})`)
        return Promise.resolve()
    }

    trackMenuNavigation(rootNode: string, path: string): Promise<void> {
        log.info(`noopAnalyticsService.trackMenuNavigation(${rootNode}, ${path})`)
        return Promise.resolve()
    }

    trackButtonClick(buttonName: string): Promise<void> {
        log.info(`noopAnalyticsService.trackButtonClick(${buttonName})`)
        return Promise.resolve()
    }

    trackCustomEvent(eventName: string, eventData: object): Promise<void> {
        log.info(`noopAnalyticsService.trackCustomEvent(${eventName}, ${eventData})`)
        return Promise.resolve()
    }
})()
