export const CookieUtil = {
    /**
     * Gets the cookie value by name
     *
     * @param name
     */
    getCookie: (name: string): string | null => {
        const nameEQ = `${name}=`
        const cookies = document.cookie.split(";")
        const cookie = cookies.find((cookie) => cookie.trim().startsWith(nameEQ))

        return cookie?.trim()?.substring(nameEQ.length)
    },
}
