import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { ActionPopupConfig, ModalConfig } from "domain/types"
import React, { ReactNode } from "react"
import type { ModalAction } from "shared/actions/modalAction"
import type { AppDispatch, RootState } from "shared/redux/store"
import { log } from "shared/util/log"

export type ModalState = ModalConfig[]

const initialState: ModalState = []

const modalsSlice = createSlice({
    name: "modals",
    initialState,
    reducers: {
        modalAdd(state: ModalState, action: PayloadAction<ModalConfig>): void {
            state.push(action.payload)
        },
        modalUpdate(state: ModalState, action: PayloadAction<ModalConfig>): void {
            modalUpdateAux(state, { ...action, payload: action.payload })
        },
        modalClose: modalCloseAux,
        modalCancel(state: ModalState, action: PayloadAction<{ identifier: string; config: ActionPopupConfig }>): void {
            const {
                identifier,
                config: { onAbort },
            } = action.payload
            if (onAbort && typeof onAbort === "function") onAbort()
            modalCloseAux(state, { ...action, payload: { identifier } })
        },
        modalLoadingState(state: ModalState, action: PayloadAction<{ identifier: string; loading: boolean }>): void {
            const { identifier, loading } = action.payload
            modalUpdateAux(state, { ...action, payload: { identifier, contentLoading: loading } })
        },
        /**
         * In order to break a circular dependency, the content of the modal is passed as a parameter to this action.
         */
        modalContent(
            state: ModalState,
            action: PayloadAction<{
                identifier: string
                content: React.ReactNode
                title?: ReactNode
                subtitle?: ReactNode
            }>,
        ): void {
            const { identifier, content, title, subtitle } = action.payload
            modalUpdateAux(state, { ...action, payload: { identifier, content, title, subtitle } })
        },
        modalStartSubmitting(state: ModalState, action) {
            const { identifier } = action.payload
            modalUpdateAux(state, { ...action, payload: { identifier, submitting: true } })
        },
        modalStopSubmitting(state: ModalState, action) {
            const { identifier } = action.payload
            modalUpdateAux(state, { ...action, payload: { identifier, submitting: false } })
        },
    },
})

function modalUpdateAux(state: ModalState, action: PayloadAction<Partial<ModalConfig>>): void {
    const idx = getIdx(state, action.payload.identifier)
    if (idx >= 0) {
        state[idx] = { ...state[idx], ...action.payload }
    } else {
        log.warn(
            "Modal window with identifier " + action.payload.identifier + " not found. It could be already closed.",
        )
    }
}

function modalCloseAux(state: ModalState, action: PayloadAction<Partial<{ identifier: string }>>): void {
    const idx = getIdx(state, action.payload.identifier)
    if (idx >= 0) state.splice(idx, 1)
}

function defaultModalConfig(config: ActionPopupConfig, dispatch: AppDispatch): ModalConfig {
    const modalConfig: ModalConfig = {
        identifier: config.identifier,
        title: config.title,
        visible: true,
        submitting: false,
        onCancel: () => dispatch(modalCancel({ identifier: config.identifier, config })),
        onOk: (): ModalAction => dispatch(modalStartSubmitting({ identifier: config.identifier })),
        content: undefined,
        contentLoading: undefined,
        modalWidth: config.modalWidth,
        modalMinHeight: config.modalMinHeight,
        additionalFooterElements: config.additionalFooterElements,
        additionalFilters: config.additionalFilters,
    } as ModalConfig

    if (config.subtitle) modalConfig.subtitle = config.subtitle

    return modalConfig
}

function getIdx(state: ModalState, id: string): number {
    return state.findIndex((modal) => modal.identifier === id)
}

function contains(state: ModalState, id: string): boolean {
    return getIdx(state, id) >= 0
}

/**
 * modalOpen is not a regular reducer, but a thunk action that can be dispatched. Regular reducers are not allowed to
 * access the store or dispatch actions. Also, we can avoid a static import of the store as thunks receive dispatch as
 * an argument, which avoids circular dependencies.
 */
export const modalOpen = (popupConfig: ActionPopupConfig) => (dispatch: AppDispatch, getState: () => RootState) => {
    const modalConfig: ModalConfig = defaultModalConfig(popupConfig, dispatch)
    const state: ModalState = getState().modals
    if (contains(state, popupConfig.identifier)) {
        log.warn("modalOpen: modal with identifier already exists", popupConfig.identifier)
        dispatch(modalsSlice.actions.modalUpdate(modalConfig))
    } else {
        dispatch(modalsSlice.actions.modalAdd(modalConfig))
    }
}

// Export the other actions.
export const { modalClose, modalCancel, modalStartSubmitting, modalStopSubmitting, modalLoadingState, modalContent } =
    modalsSlice.actions

export default modalsSlice.reducer
