import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { UserConfigs } from "domain/user/redux/load.user.data.actions"
import { MenuRootDTO } from "generated/models"
import { LightweightUserInfoDTO, UserTreeDTO } from "generated/models"

export type UserState = UserConfigs

const initialState: UserState = {}

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        loadTree(state: UserState, action: PayloadAction<{ tree: UserTreeDTO }>): void {
            state.tree = action.payload.tree
        },
        loadMenu(state: UserState, action: PayloadAction<{ menu: MenuRootDTO }>): void {
            state.menu = action.payload.menu
        },
        loadUserInfo(state: UserState, action: PayloadAction<{ userInfo: LightweightUserInfoDTO }>): void {
            state.userInfo = action.payload.userInfo
        },
    },
})

export const { loadTree, loadMenu, loadUserInfo } = userSlice.actions

export default userSlice.reducer
