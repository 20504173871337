import { PasswordExpirationDTO } from "generated/models"
import { get } from "shared/service"
import UrlService from "shared/service/url.service"

const loadPasswordExpiration = (): Promise<PasswordExpirationDTO> => {
    return get(`${UrlService.getUiServiceApiUrl()}/password/expiration`)
}

export const PasswordExpirationService = {
    loadPasswordExpiration: loadPasswordExpiration,
}
