import { Theme } from "@mui/material/styles"
import { Typography } from "@mui/material/styles/createTypography"
import { FONT_SIZE_BODY_LARGE, FONT_SIZE_BODY_MAIN_HEADLINE, FONT_SIZE_BODY_SMALL } from "styles/theme/constants"

/**
 * Intentionally limiting this to only 2 screen sizes to avoid having too many different cases to test
 * @param theme
 * @param small font size for smaller screens (xs, sm, md - everything below large)
 * @param large font size for larger screens (lg, xl)
 */
const generateResponsiveFontSize = (theme: Theme, small: string, large: string) => ({
    fontSize: small,
    // use this if you want to have different font sizes for different screen sizes
    // [theme.breakpoints.up('lg')]: {
    //     fontSize: large,
    // }
})

export default function fontSizeOverrides(theme: Theme): Typography {
    return {
        ...theme.typography,
        button: generateResponsiveFontSize(theme, FONT_SIZE_BODY_SMALL, FONT_SIZE_BODY_LARGE),
        body1: generateResponsiveFontSize(theme, FONT_SIZE_BODY_SMALL, FONT_SIZE_BODY_LARGE),
        body2: generateResponsiveFontSize(theme, FONT_SIZE_BODY_SMALL, FONT_SIZE_BODY_LARGE),
        h1: {
            fontSize: FONT_SIZE_BODY_MAIN_HEADLINE,
        },
    }
}
