const joinUrl = (baseURL: string, ...paths: string[]): string => {
    // Ensure the base URL ends with a single /
    let finalUrl = baseURL.replace(/\/+$/, "") + "/"

    // Process each path segment
    paths.forEach((path) => {
        // Add each segment to the final URL, ensuring no double / are created
        finalUrl += path.replace(/^\/+/, "").replace(/\/+$/, "") + "/"
    })

    // Remove the trailing /
    return finalUrl.replace(/\/+$/, "")
}

const UrlUtil = {
    joinUrl: joinUrl,
}

export default UrlUtil
