import { MenuRootDTO } from "generated/models"
import { LightweightUserInfoDTO, UserTreeDTO } from "generated/models"
import { get } from "shared/service"
import UrlService from "shared/service/url.service"

const USER_SERVICE_URL: string = `${UrlService.getUserServiceApiUrl()}/userinfo`
const USER_MENU_URL: string = `${UrlService.getUiServiceApiUrl()}/menu`

const loadMenu = (): Promise<MenuRootDTO> => get(`${USER_MENU_URL}`)
const loadTree = (): Promise<UserTreeDTO> => get(`${USER_SERVICE_URL}/gettree`)
const loadUserInfo = (): Promise<LightweightUserInfoDTO> => get(`${USER_SERVICE_URL}/current-lightweight`)

const UserService = {
    loadMenu: loadMenu,
    loadTree: loadTree,
    loadUserInfo: loadUserInfo,
}

export default UserService
