/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime"
import type { UserSettingsDTO } from "./UserSettingsDTO"
import { UserSettingsDTOFromJSON, UserSettingsDTOFromJSONTyped, UserSettingsDTOToJSON } from "./UserSettingsDTO"

/**
 *
 * @export
 * @interface UserSettingsResponseDTO
 */
export interface UserSettingsResponseDTO {
    /**
     *
     * @type {Array<UserSettingsDTO>}
     * @memberof UserSettingsResponseDTO
     */
    settings: Array<UserSettingsDTO>
}
/**
 * Check if a given object implements the UserSettingsResponseDTO interface.
 */
export function instanceOfUserSettingsResponseDTO(value: object): value is UserSettingsResponseDTO {
    if (!("settings" in value) || value["settings"] === undefined) return false
    return true
}

export function UserSettingsResponseDTOFromJSON(json: any): UserSettingsResponseDTO {
    return UserSettingsResponseDTOFromJSONTyped(json, false)
}

export function UserSettingsResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserSettingsResponseDTO {
    if (json == null) {
        return json
    }
    return {
        settings: (json["settings"] as Array<any>).map(UserSettingsDTOFromJSON),
    }
}

export function UserSettingsResponseDTOToJSON(value?: UserSettingsResponseDTO | null): any {
    if (value == null) {
        return value
    }
    return {
        settings: (value["settings"] as Array<any>).map(UserSettingsDTOToJSON),
    }
}
