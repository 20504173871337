import { NotificationService, NotificationType } from "domain/notification/NotificationService"
import { Notification, addNotification } from "domain/notification/redux/notification.slice"
import { ResponseDTO } from "generated/models"
import { Dispatch } from "redux"
import { log } from "shared/util/log"

const loadNotificationCount =
    (type: NotificationType) =>
    (dispatch: Dispatch): Promise<Notification> => {
        return NotificationService.loadNotificationCount(type).then((response) => {
            const result: Notification = { type: type, count: response.count }
            dispatch(addNotification(result))
            return result
        })
    }

const dismissNotification =
    (type: NotificationType) =>
    (dispatch: Dispatch): Promise<ResponseDTO> => {
        return NotificationService.dismissNotification(type).then((response) => {
            if (response.success) {
                const notification: Notification = { type: type, count: 0 }
                dispatch(addNotification(notification))
            } else {
                log.error(`Notification ${type} couldn't be updated`)
            }

            return response
        })
    }

export const notificationActions = {
    dismissNotification: dismissNotification,
    loadNotificationCount: loadNotificationCount,
}
