import { PartialDeep } from "type-fest"

const isObject = (value: any): boolean => value && typeof value === "object" && !Array.isArray(value)

/**
 * Deeply update an object in-place with the values from another object.
 *
 * NOTE: Possibly not entirely type-safe.
 *
 * @param obj
 * @param overrides
 */
const updateObjectDeep = <A>(obj: A, overrides: PartialDeep<A>): A => {
    for (const key in overrides) {
        if (Object.prototype.hasOwnProperty.call(overrides, key)) {
            const overrideValue = (overrides as any)[key]
            const originalValue = (obj as any)[key]

            if (overrideValue === null) {
                continue
            }

            if (isObject(overrideValue) && isObject(originalValue)) {
                updateObjectDeep(originalValue, overrideValue)
            } else {
                ;(obj as any)[key] = overrideValue
            }
        }
    }
    return obj
}

export const ObjectUtil = {
    updateObjectDeep,
}

export default ObjectUtil
